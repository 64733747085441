<template>
  <section class="outContainer">
    <!-- 搜索 -->
    <div class="search">
      <el-input class="each" v-model="searchInfo.param.userPhone" placeholder="联系号码"></el-input>
      <el-input class="each" v-model="searchInfo.param.invoiceTitle" placeholder="购买方名称（抬头）"></el-input>
      <el-select class='each' placeholder="开票状态" v-model="searchInfo.param.status" clearable>
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button size="mini" type="primary" icon="el-icon-search" @click="toSearch">搜索</el-button>
      <el-button size="mini" type="primary" icon="el-icon-close"  @click="backAll">清空搜索条件</el-button>
    </div>
    <div class="tableContainer">
      <el-table
        :data="billInfo"
        style="width: 100%"
        height="100%"
        class="table"
        fit
      >
        <!-- 数据展示区 -->
        <el-table-column align='center' label="发票抬头" prop="invoiceTitle">
        </el-table-column>
        <el-table-column align='center' label="发票识别号（税号）" prop="invoiceNumber">
        </el-table-column>
        <el-table-column align='center' label="开票人信息" prop="TotalPhone">
          <template slot-scope="scope">
            <p>联系电话：{{ scope.row.phone ? scope.row.phone : '--' }}</p>
            <p>邮箱：{{ scope.row.emailAddress ? scope.row.emailAddress : '--' }}</p>
          </template>
        </el-table-column>
        <el-table-column align='center' label="发票类型" prop="type">
          <template slot-scope="scope">
            <p>{{ scope.row.type == 2 ? '单位' : '个人' }}</p>
          </template>
        </el-table-column>
        <el-table-column align='center' label="企业地址" prop="enterpriseAddress">
          <template slot-scope="scope">
            <p>{{scope.row.enterpriseAddress ? scope.row.enterpriseAddress : '--'}}</p>
          </template>
        </el-table-column>
        <el-table-column align='center' label="申请时间" prop="createTimeStr" min-width="150">
        </el-table-column>
        <el-table-column align='center' label="状态" prop="status">
          <template slot-scope="scope">
            <p>{{scope.row.status == 1 ? '申请中' : (scope.row.status == 2 ? '已开票' : (scope.row.status == 3 ? '作废' : '暂无'))}}</p>
          </template>
        </el-table-column>
        <el-table-column align='center' label="操作" fixed="right" min-width="250">
          <template slot-scope="scope">
            <el-button size="mini" @click="updateInvoice(scope.row,'yes')" v-if="scope.row.status == 1">确认开票</el-button>
            <el-button size="mini" @click="updateInvoice(scope.row,'no')" v-if="scope.row.status == 1">作废</el-button>
            <el-button size="mini" @click="checkOrder(scope.row)">开票订单</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 开票订单 -->
      <el-drawer title="开票订单" :visible.sync="detailDrawer" :direction="direction" :before-close="handleClose" size="70%">
        <detail v-if="detailDrawer" @close='handleClose' ref='newForm' :row="row"></detail>
      </el-drawer>
    </div>
    <!-- 分页 -->
    <Pagination v-bind:child-msg="searchInfo" @callFather="callFather"></Pagination>
  </section>
</template>

<script>
import { getInvoiceList,makeOutAnInvoice } from '@/api/public.js';
import detail from './detail.vue';
import Pagination from '@/components/Pagination.vue';
import { timeChange } from '@/api/publicFun.js';
export default {
  data() {
    return {
      billInfo:[], // 开票列表信息
      searchInfo:{ // 查询条件
        pageNo:1,
        pageSize:10,
        total:0,
        param:{
          invoiceTitle:'', // 发票抬头
          status:'', // 开票状态
          userPhone:'', // 开票人联系方式
        },
      },
      statusOptions:[
        {value:1,label:'申请中'},
        {value:2,label:'已开票'},
        {value:3,label:'作废'},
      ],
      detailDrawer:false, // 开票订单弹框是否打开
      row:'',
      direction:'rtl',
    }
  },

  components: {
    detail,
    Pagination,
  },

  computed: {},

  mounted() {
    this.init();
  },

  methods: {
    // 初始化获取申请列表
    async init(){
      await getInvoiceList(this.searchInfo).then(res=>{
        if(res.success){
          this.billInfo = res.data.list;
          this.searchInfo.total = res.data.total;
          this.billInfo.forEach(element => {
            element.createTimeStr = timeChange(element.createTime,'seconds');
            for(let key in element){
              element[key] = ( element[key] || element[key] == 0 ) ? (element[key] == 'null' ? '--' : element[key] ) : '--';
            }
          })
        }else{
          this.searchInfo.total = 0;
          this.billInfo = [];
        }
      })
    },
    // 确认开票/作废
    updateInvoice(row,type){
      this.$confirm('确认进行该操作？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async res=>{
        let data = {
          param:{
            id:row.id,
            invoiceTitle:row.invoiceTitle,
            status:type == 'yes' ? 2 : 3,
            userPhone:row.phone
          }
        }
        await makeOutAnInvoice(data).then(res => {
            if(res.success){
                this.$message({
                    message:'操作成功',
                    type:'success'
                })
                this.init();
            }else{
              this.$message({
                  message:res.msg,
                  type:'fail'
              })
              this.init();
            }
        })
      })
    },
    // 查看开票申请下的订单
    checkOrder(row){
      this.row = row;
      this.detailDrawer = true;
    },
    // 关闭弹框
    handleClose(){
      this.detailDrawer = false;
      this.init();
    },
    // 条件查询
    toSearch(){
      this.searchInfo.pageNo = 1;
      this.init();
    },
    // 清除查询条件
    backAll(){
      this.searchInfo.pageNo = 1;
      this.searchInfo.param = {
        invoiceTitle:'',
        status:'',
        userPhone:'',
      };
      this.init();
    },
    // 分页操作事件
    callFather(parm){
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped lang='scss'>
 .search{
  margin-bottom: 30px;
    .inputs{
      display: flex;
    }
    .wxName,.select,.timeSelect{
      width: 20%;
      margin-right: 15px;
    }
    .timeSelect{
      flex: 1;
    }
    .Btns{
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
  .search .wxName[data-v-0dade7ca], .search .select[data-v-0dade7ca], .search .timeSelect[data-v-0dade7ca]{
    margin-right: 5px;
  }
  
// 按钮
  .btns{
    width: 100%;
    background-color: white;
    height: 60px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    button{
      position: absolute;
      right:20px;
      top:10px;
    }
  }
  

.el-table >>> .cell {
  white-space: pre-line;
}
.el-drawer.rtl{
  overflow-y: auto !important;
}
.el-tabs__content{
  overflow:auto !important;
}

.equipName{
  margin-bottom: 10px;
}
.equipNumber{
  color: #40a9ff;
}
.number{
  display: inline-block;
  height: 20px;
  width: 20px;
  padding: 0 2px;
  border: solid 1px #91d5ff;
  color:#40a9ff;
  background: #e6f7ff;
  border-radius: 3px;
  margin-left: 10px;
  text-align: center;
  font-size: 14px;
}
.el-tabs__content{
  overflow:auto !important;
}
.orderStatus{
  background:#40a9ff;
  color:#fff;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 5px;
}

.stopWay{
  color: #40a9ff;
  margin-top: 10px;
}
</style>
