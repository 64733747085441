<template>
    <div class="container">
      <!-- 开票信息 -->
      <div class="detail">
          <h3>开票信息</h3>
          <p><span>开票抬头</span><b>{{ row.invoiceTitle }}</b></p>
          <p><span>发票识别号</span><b>{{ row.invoiceNumber }}</b></p>
          <p><span>发票类型</span><b>{{ row.type == 2 ? '单位' : '个人' }}</b></p>
          <p><span>开票人邮箱</span><b>{{ row.emailAddress }}</b></p>
          <p><span>开票人联系号码</span><b>{{ row.phone }}</b></p>
          <p><span>企业地址</span><b>{{ row.enterpriseAddress ? row.enterpriseAddress : '--' }}</b></p>
          <p><span>状态</span><b>{{ row.status == 1 ? '申请中' : (row.status == 2 ? '作废' : (row.status == 3 ? '已开票' : '--')) }}</b></p>
      </div>
      <!-- 开票订单 -->
      <div class="charts" style="overflow: auto;flex: 1;">
          <el-tabs v-model="activeName" style="display: flex;flex-direction: column;height: 100%;">
            <el-tab-pane label="订单信息" name="first" style="height: 100%;">
                <el-table
                  :data="orderList"
                  style="width: 100%;overflow: auto;"
                  class="table"
                  show-summary
                  fit
                >
                  <el-table-column align='center' label="订单号" prop="orderId" width="250px">
                    <template slot-scope="scope">
                      <p>{{scope.row.orderId ? scope.row.orderId : '--'}}</p>
                    </template>
                  </el-table-column>
                  <el-table-column align='center' label="订单类型" prop="type">
                    <template slot-scope="scope">
                      <p>{{scope.row.type == 1 ? '电瓶车订单' : '新能源汽车订单'}}</p>
                    </template>
                  </el-table-column>
                  <el-table-column align='center' label="实际金额（元）" prop="actualPayment">
                    <template slot-scope="scope">
                      <p>{{scope.row.actualPayment ? scope.row.actualPayment : 0}}</p>
                    </template>
                  </el-table-column>
                  <el-table-column align='center' label="电费（元）" prop="eleCharge">
                    <template slot-scope="scope">
                      <p>{{scope.row.eleCharge ? scope.row.eleCharge : 0}}</p>
                    </template>
                  </el-table-column>
                  <el-table-column align='center' label="服务费（元）" prop="serviceCharge">
                    <template slot-scope="scope">
                      <p>{{scope.row.serviceCharge ? scope.row.serviceCharge : 0}}</p>
                    </template>
                  </el-table-column>
                  <el-table-column align='center' label="退款金额（元）" prop="refund">
                    <template slot-scope="scope">
                      <p>{{scope.row.refund ? scope.row.refund : 0}}</p>
                    </template>
                  </el-table-column>
                </el-table>
            </el-tab-pane>
          </el-tabs>
      </div>
    </div>
  </template>
  
  <script>
  import { getInvoiceDetail } from '@/api/public.js';
  export default {
    props:['row'],
    data() {
      return {
          activeName:'first',
          orderList:[], // 订单列表
      }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.init();
    },
  
    methods: {
        // 获取该申请下的订单
        async init(){
          let data = {
            param:this.row.id,
          }
          await getInvoiceDetail(data).then(res=>{
              if(res.success){
                  this.orderList = res.data.orderVOList;
              }
          })
        },
    },
  };
  </script>
  <style scoped lang='scss'>
    .container{
        overflow: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .el-tabs__header /deep/ .el-tabs__content{
      overflow: auto !important;
    }
    /deep/ .el-tabs__content{
      overflow:auto !important;
      flex: 1;
      margin-bottom: 30px !important;
    }
     /deep/  .el-drawer__wrapper{
      z-index: 4000 !important;
    }
    .detail{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 50px;
      font-size: 14px;
      box-sizing: border-box;
      h3{
          width: 100%;
          height: 70px;
          line-height: 70px;
          text-align: left;
      }
      p{
          line-height: 16px;
          box-sizing: border-box;
          display: flex;
          width: 50%;
          span{
              display: inline-block;
              background: #fafafa;
              border:solid #f0f0f0 1px;
              width: 30%;
              box-sizing: border-box;
              height: 40px;
              line-height: 40px;
              box-sizing: border-box;
          }
          b{
               display: inline-block;
              background: #fff;
               border:solid #f0f0f0 1px;
               box-sizing: border-box;
               height: 40px;
               line-height: 40px;
               flex: 1;
           }
      }
    }
    .charts{
      width: 90%;
      margin: 0 auto;
      height: 100%;
    }
    /deep/ .el-tabs__content{
      height: auto !important;
      overflow: auto !important;
    }
  </style>
  